// Libs
import React, { useState, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import FeaturedMarketing from 'components/featuredMarketing';
import PromptServiceContent from 'components/promptService/promptServiceContent';
import Accordion from 'components/Accordion';
import ReactMarkdown from 'react-markdown';
import { Certificates } from '../../../components/certificates/index';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};

  @media ${device.laptop} {
    flex-direction: column;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 0;
  }
`;

export const AccordionContainer = styled.div`
  @media ${device.laptop} {
    margin-bottom: 1.875rem;

    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const AccordionContainerTitle = styled(ReactMarkdown)`
  p,
  h2 {
    font-size: 2.25rem;
    font-weight: 600;
    text-align: center;
    margin: 4.375rem 0;

    @media ${device.laptop} {
      font-size: 1.625rem;
      margin: 1.875rem 0;
    }
  }
`;

const Part = styled.div`
  display: ${props => props.isDisplay};
  grid-column: ${props => props.gridColumn};
  border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  padding-top: ${props => props.paddingTop && '70px'};
  padding-bottom: ${props => props.paddingBottom && '70px'};
  border-top: ${props => props.borderTop && '1px solid #C6C8CC'};

  :last-child {
    border-bottom: ${props => props.borderBottom && 'unset'};
  }

  section :first-child {
    padding: ${props =>
      props.isManualPadding
        ? props.isManualPadding
        : props.notPadding
        ? '70px 0 0 !important'
        : props.notPaddingTop && '0 0 70px !important'};
    p {
      padding-left: ${props => props.notLeft && '0'};
    }

    @media ${device.laptop} {
      width: ${props => props.notPaddingTop && '100%'};
      padding: ${props =>
        props.notPadding
          ? '30px 0 0 !important'
          : props.notPaddingTop && '30px 0 !important'};
      margin: 0;
      border-bottom: ${props => props.notPaddingTop && 'unset'};
    }

    @media ${device.tablet} {
      padding: ${props => props.notPaddingTop && '0 0 30px !important'};
    }
  }

  section :last-child {
    padding: ${props => props.shortPadding && '20px 0 70px'};
    @media ${device.laptop} {
      padding: ${props => props.shortPadding && '1.25rem 0 30px'};
    }
  }

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIBelaVista ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-top: ${props => props.paddingTop && '30px'};
    padding-bottom: ${props => props.notPaddingTop && '30px'};
    padding-bottom: ${props => props.paddingBottom && '0'};
    padding-bottom: ${props => props.paddingBottomPersonalize && '1.875rem'};
    border-bottom: ${props =>
      props.borderBottom || props.test
        ? 'unset'
        : props.notPaddingTop && '1px solid #C6C8CC'};
    border-top: ${props => props.paddingTop && '1px solid #C6C8CC'};

    section {
      div {
        display: ${props => props.paddingTop && 'flex'};
      }
    }
  }

  @media ${device.tablet} {
    padding-top: ${props => props.paddingTop && '0'};
    border-top: ${props => props.paddingTop && 'unset'};
  }
  @media ${device.laptop} {
    border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
    :last-child {
      border-bottom: ${props => props.borderBottom && 'unset'};
    }
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderPage = (page, isDesktop) => {
  return (
    <GridContainer isDesktop={isDesktop}>
      <Part gridColumn={'2 / -2'}>
        <FeaturedMarketing
          markdown={getMarkdown(page, 'cktxf7dnk0a9x0b748ncln9xr', true)}
          breadCrumb={getMarkdown(page, 'cktxf61tc0a2q0b7456b7pus7', true)}
          asset={getMarkdown(page, 'cktxf7dnk0a9x0b748ncln9xr', false, true)}
          isOncologia
          titleWidthLap="30rem"
          boxInfoTextWidthDesk="28.125rem"
          boxContentPaddingBottomDesk="0"
          boxInfoParagraphImgWidth="unset"
          boxInfoParagraphImgHeight="unset"
          marginTopTitle="3.125rem"
          titleMarginLap="0"
          containerNoPaddingTopLap
          boxImagesImgOuterWrapperWidth="unset"
          marginTopBoxInfoMob="-7rem"
          marginTopBoxInfoTab="-9rem"
          positionLeftImageMarketingMobile320="1.725rem"
          positionLeftImageMarketingMobile="0.7rem"
          overflowContainerMobile="hidden"
          positionTopImageMarketingMobile="0.rem"
          widthImgMarketingMobile="10rem"
          widthImgMarketingTab="17rem"
          widthImgMarketingLap="25rem"
          marginBottomTitleDesk="5.5rem"
          boxContentFlexDirectionTab="column-reverse"
          boxContentFlexDirectionLap="unset"
          titleWidthMob="14rem"
          titleWidthMob320="50%"
          titleFontSizeMob360="1.25rem"
          titleFontSizeMob320="1.125rem"
          boxInfoHeightLap="9rem"
          titleWidthDesk="35.625rem"
          widthImgMarketingDesk="33.75rem"
          heightImgMarketingDesk="27.625rem"
          ImgAdjustWidth="35.625rem !important"
          ImgAdjustHeight="35.625rem !important"
          marginBreadCrumb="0 0 0"
          justifyContentFeaturedMarketing="end"
          boxInfoHeightMobile="8rem"
        />
      </Part>

      <Part
        gridColumn={'2 / -2'}
        largeTitle
        notPadding
        notLeft
        shortPadding
        borderBottom
      >
        <PromptServiceContent
          markdown={getMarkdown(page, 'cktxfclxc0dg30b798db8id3x', true)}
          fragments={getMarkdown(page, 'cktxfclxc0dg30b798db8id3x')}
          padding
          notTitleBold
          facilitiesForYou
          isBottom
          prontoAtendimento
          isFacilities
          isPatologica
          isFragmentTitle
          withoutBorderTop
          boxContentPaddingBottomLaptop="0"
        />

        <PromptServiceContent
          markdown={getMarkdown(page, 'ckwghoyy81lwo0c71dtpy9a6j', true)}
          fragments={getMarkdown(page, 'ckwghoyy81lwo0c71dtpy9a6j')}
          padding
          notTitleBold
          facilitiesForYou
          isDropdownDesktop
          isPatologica
          containerPaddingDesk="1.875rem 0 0"
          containerPaddingLaptop="1.25rem 0 0"
          contentFontSizeMob="0.875rem"
          isFragmentTitle
          displayTitle="none"
          cuidados
          paddingLeftListDesk=".8rem"
          paddingRightListDesk="2rem"
          infusion
          isPageDoeSangue
          containerQualidadeMarginTop
          ButtonTagPTextAlign="initial"
        />
        <PromptServiceContent
          markdown={getMarkdown(page, 'ckwgi5gbc1xq60b735de9833g', true)}
          fragments={getMarkdown(page, 'ckwgi5gbc1xq60b735de9833g')}
          padding
          notTitleBold
          facilitiesForYou
          isDropdownDesktop
          isPatologica
          containerPaddingDesk="1.875rem 0 0"
          containerPaddingLaptop="1.25rem 0 0"
          contentFontSizeMob="0.875rem"
          isFragmentTitle
          displayTitle="none"
          cuidados
          paddingLeftListDesk=".8rem"
          paddingRightListDesk="2rem"
          infusion
          isPageDoeSangue
          ButtonTagPTextAlign="initial"
        />
        <PromptServiceContent
          markdown={getMarkdown(page, 'ckwgi8zmo1ylj0b73w5sfzphq', true)}
          fragments={getMarkdown(page, 'ckwgi8zmo1ylj0b73w5sfzphq')}
          padding
          notTitleBold
          facilitiesForYou
          isDropdownDesktop
          isPatologica
          containerPaddingDesk="1.875rem 0 0"
          containerPaddingLaptop="1.25rem 0 0"
          contentFontSizeMob="0.875rem"
          isFragmentTitle
          displayTitle="none"
          cuidados
          paddingLeftListDesk=".8rem"
          paddingRightListDesk="2rem"
          infusion
          isPageDoeSangue
          ButtonTagPTextAlign="initial"
        />
        <PromptServiceContent
          markdown={getMarkdown(page, 'ckwgiavj41zf40a75v9f2pgsk', true)}
          fragments={getMarkdown(page, 'ckwgiavj41zf40a75v9f2pgsk')}
          padding
          notTitleBold
          facilitiesForYou
          isDropdownDesktop
          isPatologica
          containerPaddingDesk="1.875rem 0 0"
          containerPaddingLaptop="1.25rem 0 0"
          contentFontSizeMob="0.875rem"
          isFragmentTitle
          displayTitle="none"
          cuidados
          paddingLeftListDesk=".8rem"
          paddingRightListDesk="2rem"
          infusion
          isPageDoeSangue
          ButtonTagPTextAlign="initial"
        />
        <PromptServiceContent
          markdown={getMarkdown(page, 'ckwgif9pk23x10a75mqapb7qw', true)}
          fragments={getMarkdown(page, 'ckwgif9pk23x10a75mqapb7qw')}
          padding
          notTitleBold
          facilitiesForYou
          isDropdownDesktop
          isPatologica
          containerPaddingDesk="1.875rem 0 0"
          containerPaddingLaptop="1.25rem 0 0"
          contentFontSizeMob="0.875rem"
          isFragmentTitle
          displayTitle="none"
          cuidados
          paddingLeftListDesk=".8rem"
          paddingRightListDesk="2rem"
          infusion
          isPageDoeSangue
          ButtonTagPTextAlign="initial"
        />
        <PromptServiceContent
          markdown={getMarkdown(page, 'ckwgihck023xa0b73tc6idy3b', true)}
          fragments={getMarkdown(page, 'ckwgihck023xa0b73tc6idy3b')}
          padding
          notTitleBold
          facilitiesForYou
          isDropdownDesktop
          isPatologica
          containerPaddingDesk="1.875rem 0 0"
          containerPaddingLaptop="1.25rem 0 0"
          contentFontSizeMob="0.875rem"
          isFragmentTitle
          displayTitle="none"
          cuidados
          paddingLeftListDesk=".8rem"
          paddingRightListDesk="2rem"
          infusion
          isPageDoeSangue
          ButtonTagPTextAlign="initial"
        />
      </Part>
      <Part
        gridColumn={'2 / -2'}
        largeTitle
        notPadding
        notLeft
        shortPadding
        borderBottom
        paddingBottom
      >
        <PromptServiceContent
          markdown={getMarkdown(page, 'cktx5j32o04h10c78ljvl7nca', true)}
          fragments={getMarkdown(page, 'cktx5j32o04h10c78ljvl7nca')}
          padding
          notTitleBold
          facilitiesForYou
          isBottom
          prontoAtendimento
          isFacilities
          contentTagImgTransform="unset"
          withoutBorderTop
          noBlockContent="none"
          hasIframe
          contentImgWidthLaptop="100%"
          notDuplicateUl="none"
        />
      </Part>
      <Part
        gridColumn={'2 / -2'}
        largeTitle
        notPadding
        notLeft
        shortPadding
        borderBottom
        paddingBottom
      >
        <PromptServiceContent
          markdown={getMarkdown(page, 'cktx5ljt406zc0b19azlgz877', true)}
          fragments={getMarkdown(page, 'cktx5ljt406zc0b19azlgz877')}
          padding
          notTitleBold
          facilitiesForYou
          isBottom
          prontoAtendimento
          isFacilities
          contentTagImgTransform="unset"
          withoutBorderTop
          contentImgWidthLaptop="100%"
          notDuplicateUl="none"
        />
      </Part>
      <Part
        gridColumn={'2 / -2'}
        largeTitle
        notPadding
        notLeft
        shortPadding
        borderBottom
        paddingBottom
      >
        <PromptServiceContent
          markdown={getMarkdown(page, 'cktx5mk2o071o0b71mu4u1cc7', true)}
          fragments={getMarkdown(page, 'cktx5mk2o071o0b71mu4u1cc7')}
          padding
          notTitleBold
          facilitiesForYou
          isBottom
          prontoAtendimento
          isFacilities
          contentTagImgTransform="unset"
          titleWidth
          withoutBorderTop
          contentImgWidthLaptop="100%"
          notDuplicateUl="none"
        />
      </Part>
      <Part gridColumn={'2 / -2'} notLeft>
        <AccordionContainer>
          <AccordionContainerTitle children={'Indicadores'} />
          <div>
            <Accordion
              markdownContent={getMarkdown(
                page,
                'cktxag0o801tm0b26ombtpggx',
                true
              )}
              isQualityAndHealth
            >
              <PromptServiceContent
                markdown={getMarkdown(page, 'cktxag0o801tm0b26ombtpggx', true)}
                fragments={getMarkdown(page, 'cktxag0o801tm0b26ombtpggx')}
                containerPaddingDesk="0"
                notTitleBold
                facilitiesForYou
                isBottom
                prontoAtendimento
                isFacilities
                contentTagImgTransform="unset"
                titleWidth
                withoutBorderTop
                hasIframe
                contentImgWidthLaptop="100%"
                notDuplicateUl="none"
                notSpanTitle
                isAccordion
                isImgAndText="#1D52A3"
              />
            </Accordion>
            <Accordion
              markdownContent={getMarkdown(
                page,
                'cktxan07s3gd00c788zw0u73u',
                true
              )}
              isQualityAndHealth
            >
              <PromptServiceContent
                markdown={getMarkdown(page, 'cktxan07s3gd00c788zw0u73u', true)}
                fragments={getMarkdown(page, 'cktxan07s3gd00c788zw0u73u')}
                containerPaddingDesk="0"
                notTitleBold
                facilitiesForYou
                isBottom
                prontoAtendimento
                isFacilities
                contentTagImgTransform="unset"
                titleWidth
                withoutBorderTop
                hasIframe
                contentImgWidthLaptop="100%"
                notDuplicateUl="none"
                notSpanTitle
                isAccordion
                isOrderMobileAndTablet="-2"
                isImgAndText="#1D52A3"
              />
            </Accordion>
            <Accordion
              markdownContent={getMarkdown(
                page,
                'cktxatvwg0flq0b26s6mxywjj',
                true
              )}
              isQualityAndHealth
            >
              <PromptServiceContent
                markdown={getMarkdown(page, 'cktxatvwg0flq0b26s6mxywjj', true)}
                fragments={getMarkdown(page, 'cktxatvwg0flq0b26s6mxywjj')}
                containerPaddingDesk="0"
                notTitleBold
                facilitiesForYou
                isBottom
                prontoAtendimento
                isFacilities
                contentTagImgTransform="unset"
                withoutBorderTop
                hasIframe
                contentImgWidthLaptop="100%"
                notDuplicateUl="none"
                notSpanTitle
                isAccordion
                isImgAndText="#1D52A3"
              />
            </Accordion>
            <Accordion
              markdownContent={getMarkdown(
                page,
                'cktxe9mdk0f430c75cl0dg1yj',
                true
              )}
              isQualityAndHealth
            >
              <PromptServiceContent
                markdown={getMarkdown(page, 'cktxe9mdk0f430c75cl0dg1yj', true)}
                fragments={getMarkdown(page, 'cktxe9mdk0f430c75cl0dg1yj')}
                containerPaddingDesk="0"
                notTitleBold
                facilitiesForYou
                isBottom
                prontoAtendimento
                isFacilities
                contentTagImgTransform="unset"
                titleWidth
                withoutBorderTop
                hasIframe
                contentImgWidthLaptop="100%"
                notDuplicateUl="none"
                notSpanTitle
                isAccordion
                isImgAndText="#1D52A3"
              />
            </Accordion>
            <Accordion
              markdownContent={getMarkdown(
                page,
                'cktxefdxs1eru0g74oflk0oni',
                true
              )}
              isQualityAndHealth
            >
              <PromptServiceContent
                markdown={getMarkdown(page, 'cktxefdxs1eru0g74oflk0oni', true)}
                fragments={getMarkdown(page, 'cktxefdxs1eru0g74oflk0oni')}
                containerPaddingDesk="0"
                notTitleBold
                facilitiesForYou
                isBottom
                prontoAtendimento
                isFacilities
                contentTagImgTransform="unset"
                titleWidth
                withoutBorderTop
                hasIframe
                contentImgWidthLaptop="100%"
                notDuplicateUl="none"
                notSpanTitle
                isAccordion
                isImgAndText="#1D52A3"
              />
            </Accordion>
          </div>
        </AccordionContainer>
      </Part>
      <Part gridColumn={'2 / -2'} notLeft>
        <AccordionContainer>
          <AccordionContainerTitle children={'Certificações'} />
          <div>
            <Accordion
              markDownTitle={getMarkdown(
                page,
                'ckw6nw4ps4fmy0d690mzln6fk',
                false
              )}
            >
              <Certificates
                markdown={getMarkdown(page, 'ckw6nw4ps4fmy0d690mzln6fk', true)}
                asset={getMarkdown(
                  page,
                  'ckw6nw4ps4fmy0d690mzln6fk',
                  false,
                  true
                )}
              />
            </Accordion>
            <br />
            <Accordion
              markDownTitle={getMarkdown(
                page,
                'cktxeqg3416tt0a28pr8rtf36',
                false
              )}
            >
              <Certificates
                markdown={getMarkdown(page, 'cktxeqg3416tt0a28pr8rtf36', true)}
                asset={getMarkdown(
                  page,
                  'cktxeqg3416tt0a28pr8rtf36',
                  false,
                  true
                )}
              />
            </Accordion>
            <br />
            <Accordion
              markDownTitle={getMarkdown(
                page,
                'cktxeuyw8020d0a18s40arogx',
                false
              )}
            >
              <Certificates
                markdown={getMarkdown(page, 'cktxeuyw8020d0a18s40arogx', true)}
                asset={getMarkdown(
                  page,
                  'cktxeuyw8020d0a18s40arogx',
                  false,
                  true
                )}
              />
            </Accordion>
            <br />
            <Accordion
              markDownTitle={getMarkdown(
                page,
                'cktxewvkg02vx0a18pc3f5ehy',
                false
              )}
            >
              <Certificates
                markdown={getMarkdown(page, 'cktxewvkg02vx0a18pc3f5ehy', true)}
                asset={getMarkdown(
                  page,
                  'cktxewvkg02vx0a18pc3f5ehy',
                  false,
                  true
                )}
              />
            </Accordion>
            <br />
            <Accordion
              markDownTitle={getMarkdown(
                page,
                'cktxey4bk04t90a18yg1na0tq',
                false
              )}
            >
              <Certificates
                markdown={getMarkdown(page, 'cktxey4bk04t90a18yg1na0tq', true)}
                asset={getMarkdown(
                  page,
                  'cktxey4bk04t90a18yg1na0tq',
                  false,
                  true
                )}
              />
            </Accordion>
            <br />
            <Accordion
              markDownTitle={getMarkdown(
                page,
                'cktxezyog07hf0a181e6uuamw',
                false
              )}
            >
              <Certificates
                markdown={getMarkdown(page, 'cktxezyog07hf0a181e6uuamw', true)}
                asset={getMarkdown(
                  page,
                  'cktxezyog07hf0a181e6uuamw',
                  false,
                  true
                )}
              />
            </Accordion>
            <br />
            <Accordion
              markDownTitle={getMarkdown(
                page,
                'cktxf1mv410yq0c78ca3zgyld',
                false
              )}
            >
              <Certificates
                markdown={getMarkdown(page, 'cktxf1mv410yq0c78ca3zgyld', true)}
                asset={getMarkdown(
                  page,
                  'cktxf1mv410yq0c78ca3zgyld',
                  false,
                  true
                )}
              />
              <br />
            </Accordion>
            <br />
            <Accordion
              markDownTitle={getMarkdown(
                page,
                'cl6qk45sltpi20en4ke3wwyts',
                false
              )}
            >
              <Certificates
                markdown={getMarkdown(page, 'cl6qk45sltpi20en4ke3wwyts', true)}
                asset={getMarkdown(
                  page,
                  'cl6qk45sltpi20en4ke3wwyts',
                  false,
                  true
                )}
              />
              <br />
            </Accordion>
            <br />
            <Accordion
              markDownTitle={getMarkdown(
                page,
                'cl6wnvsto4ysf0bmwxi4x3slq',
                false
              )}
            >
              <Certificates
                markdown={getMarkdown(page, 'cl6wnvsto4ysf0bmwxi4x3slq', true)}
                asset={getMarkdown(
                  page,
                  'cl6wnvsto4ysf0bmwxi4x3slq',
                  false,
                  true
                )}
              />
              <br />
            </Accordion>
          </div>
        </AccordionContainer>
      </Part>
      <Part
        gridColumn={'2 / -2'}
        borderBottom
        paddingBottom
        notLeft
        paddingBottomPersonalize
      >
        <AccordionContainer>
          <AccordionContainerTitle
            children={'Protocolos Clínicos Gerenciados'}
          />
          <div>
            <Accordion
              markdownContent={getMarkdown(
                page,
                'cktx5xbf40jxv0b83ce9k7dps',
                true
              )}
              isFormatted
              isAdjustAccordionMargin
              isPaddingAccordion
            />
            <Accordion
              markdownContent={getMarkdown(
                page,
                'cktx681zs0wtp0b836a0ratnz',
                true
              )}
              isFormatted
              isAdjustAccordionMargin
              isPaddingAccordion
            />
            <Accordion
              markdownContent={getMarkdown(
                page,
                'cktx6bzyw0xkd0b19m4n1yyq8',
                true
              )}
              isFormatted
              isPaddingAccordion
            />
            <Accordion
              markdownContent={getMarkdown(
                page,
                'cktx6e5wg0zi40b83o2lphjrq',
                true
              )}
              isFormatted
              isPaddingAccordion
            />
            <Accordion
              markdownContent={getMarkdown(
                page,
                'cktx6hog014xk0b19x0b22oph',
                true
              )}
              isFormatted
              isPaddingAccordion
            />
            <Accordion
              markdownContent={getMarkdown(
                page,
                'cktx6lyrk17vv0b83bj9en6p2',
                true
              )}
              isFormatted
              isPaddingAccordion
            />
            <Accordion
              markdownContent={getMarkdown(
                page,
                'cktx6sgk819n40b71p34mr6w7',
                true
              )}
              isFormatted
              isPaddingAccordion
            />
            <Accordion
              markdownContent={getMarkdown(
                page,
                'cktx742rs1jb10b71cn3tv0wp',
                true
              )}
              isFormatted
              isPaddingAccordion
            />
          </div>
        </AccordionContainer>
      </Part>
      <Part
        gridColumn={'2 / -2'}
        borderBottom
        paddingBottom
        notLeft
        paddingBottomPersonalize
      >
        <AccordionContainer>
          <AccordionContainerTitle children={'Desfecho Clínico'} />
          <div>
            <Accordion
              markdownContent={getMarkdown(
                page,
                'clqz8nt801i8g0cn3yq928jjv',
                true
              )}
              isFormatted
              isPaddingAccordion
            />
            <Accordion
              markdownContent={getMarkdown(
                page,
                'clpk05v4p0ndj0bn1itq08v2s',
                true
              )}
              isFormatted
              isAdjustAccordionMargin
              isPaddingAccordion
            />
            <Accordion
              markdownContent={getMarkdown(
                page,
                'clpk09q710pva0bn1kggfg2wg',
                true
              )}
              isFormatted
              isAdjustAccordionMargin
              isPaddingAccordion
            />
            <Accordion
              markdownContent={getMarkdown(
                page,
                'clpk0cs810wiw0bn6wlb9uckv',
                true
              )}
              isFormatted
              isPaddingAccordion
            />
            <Accordion
              markdownContent={getMarkdown(
                page,
                'clqz79rx9068j0cn379mxtcye',
                true
              )}
              isFormatted
              isPaddingAccordion
            />
          </div>
        </AccordionContainer>
      </Part>
    </GridContainer>
  );
};
const QualityAndSafety = ({ page }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  const getImage = getMarkdown(page, 'cktxf7dnk0a9x0b748ncln9xr', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {renderPage(page, isDesktop)}
    </Layout>
  );
};

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckw2htjnsoa2v0a26oe17w6t7" }) {
                fragments {
                  id
                  name
                  singletexts
                  markdown
                  localizations {
                    singletexts
                    markdown
                    datafragment
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <QualityAndSafety page={response.gcms.site.pages} />;
      }}
    />
  );
};
